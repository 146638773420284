import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

(async () => {
  // inject Dynatrace script if defined
  const dynatraceScriptUrl = process.env.REACT_APP_DYNATRACE_SCRIPT_TAG_URL;
  if (dynatraceScriptUrl && dynatraceScriptUrl !== 'undefined') {
    const script = document.createElement('script');
    script.src = dynatraceScriptUrl;
    script.crossOrigin = 'anonymous';
    script.async = true;
    document.head.appendChild(script);
  }

  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_MOCK !== 'none'
  ) {
    const { worker } = require('./mocks/browser'); //eslint-disable-line
    worker.start();
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
  });

  ReactDOM.render(
    <LDProvider>
      <App />
    </LDProvider>,
    document.getElementById('root'),
  );
})();
